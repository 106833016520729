<!-- 安全中心设置密码步骤 设置支付密码验证手机号滑动验证-->
<template>
  <div class="setsteps">
    <div class="top">
      <el-row class='head'>
        <el-col :span="12">
          <logotitle :title='!this._spf(this.$route.query.types)?"设置支付密码":["","","","找回密码"][this._spf(this.$route.query.types)]'></logotitle>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
    </div>
    <div class="stepcnt">
      <div class="group">
        <input type="text" v-model='users' placeholder="请输入已验证手机号">
        <span class="err" v-if='errormsg.user'>{{errormsg.user}}</span>
      </div>
      <div  class="plug">
        <el-popover
          placement="top"
          trigger="click">
          <div class="expbox">
            <div class="verifytop" style="display: flex;justify-content: space-between;height: 30px;align-items: center;">
              <p>完成拼图验证</p>
              <button @click='resetimg' style="background: none;color:#f78385;"><svg style='vertical-align: -3px;fill:#f78385;margin-right: 5px;'><use xlink:href="#iconhuanyizhang"></use></svg>换一张</button>
            </div>
            <slide-verify :l="42"
            :r="10"
            :w="310"
            :h="155"
            :show='false'
            ref="slideblock"
            slider-text="向右滑动"
            @success="onSuccess"
            @fail="onFail"
            ></slide-verify>
          </div>
          <button  class="expbtn"  slot="reference" :class='{btn1:verifys,btn2:!verifys&&verifys!=null}'>
            <template v-if='verifys==null'><span  class="icon"><svg><use xlink:href="#iconwenxintishi-"></use></svg></span>点击进行验证</template>
            <template v-if='!verifys&&verifys!=null'><span  class="icon iconx"><svg><use xlink:href="#iconshanchu"></use></svg></span>认证失败</template>
            <template  v-if='verifys'><span  class="icon icono"><svg><use xlink:href="#iconwancheng"></use></svg></span>认证成功</template>
          </button>
        </el-popover>
        <span class="err" v-if='errormsg.verify'>{{errormsg.verify}}</span>
      </div>
      <div class="group">
        <button @click='next' :disabled="disabled">下一步</button>
      </div>
    </div>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
export default {
  name: 'setsteps',
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'安全中心-微微定',
      // visible:false,
      verifys:null,
      users:'',//账号
      errormsg:{
        user:null,
        verify:null
      },
      disabled:false
    }
  },
  components: {
    logotitle,
  },
  methods:{
    resetimg(){
      // 刷新验证码
      this.verifys = null
      this.$refs.slideblock.reset();
    },
    onSuccess(){
      // 验证成功
      this.verifys = true
    },
    onFail(){
      // 验证失败
      this.verifys = false
    },
    next(){
      var _flag = true;
      this.errormsg.user=null
      this.errormsg.verify=null
      // 下一步验证
      if(this.users==''){
        _flag = false;
        this.errormsg.user='请填写账号信息'
      }
      if(!this.verifys){
        _flag = false;
        this.errormsg.verify='请点击认证'
      }
      if(_flag){
        this.disabled = true
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/CheckPhone?phone='+this.users,this.keys())
          .then(res => {
              if(res.data.Success){
                this.disabled = false
                if(!this._spf(this.$route.query.types)){
                  // 跳转验证第二步
                  this._router({then:()=>{
                    this.$router.push('/idverify?types=0.html')
                  }})
                }else if(this._spf(this.$route.query.types)==3){
                  // 跳转验证第二步
                  this._router({then:()=>{
                    this.$router.push('/idverify?phone='+this.users+'&types=3.html')
                  }})
                }
              }else{
                this.disabled = false
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
              console.log('系统异常')
            })
        },exp:false,catch:()=>{
              this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
  }
}
</script>
<style  lang="scss" scoped>
.setsteps {
  padding-bottom:40px;
  .top {
    width: 100%;background: #fff;padding:10px 0px;box-shadow: 0px 3px 5px 0px rgba(212,212,212,0.4);position: relative;z-index: 9;
    .head {
      width: 1200px;margin:0 auto;
      .rechargestep {
        float:right;margin-top:5px;
      }
    }
  }
  .stepcnt {
    width: 100%;background: #fff;padding-top:180px;padding-bottom:300px;
    .group {
      width: 389px;margin:0 auto;position: relative;
      input {
        width: 367px;height: 47px;border:1px solid #e9e9e9;border-radius: 5px;padding-left:20px;
        &::placeholder {
          font-size:14px;color:#b9b9b9;
        }
      }
      button {
        width: 389px;height: 49px;color:#fff;background: #f44e55;font-size: 16px;border-radius: 5px;
      }
      .err {
        position: absolute;width: 389px;left:0px;top:50px;font-size: 12px;color:#f44e55;
      }
    }
    .plug {
      width: 389px;margin:0 auto;margin-top:30px;margin-bottom:30px;position: relative;
      .expbtn {
        width: 389px;height: 49px;background: #f7f8f9;border:1px solid #eceded;border-radius: 5px;display: flex;justify-content: center;align-items: center;color:#484849;font-size: 14px;
        .icon {
          width: 27px;height: 27px;background: #fff;border-radius: 50%;border:1px solid #e0e0df;line-height: 36px;margin-right: 9px;
          svg {
            fill: #a0a1a2;width: 18px;height: 18px;
          }
        }
        .iconx {
          border-color:#f4657a;
          svg {
            fill: #f4657a;
          }
        }
        .icono {
          border-color:#59d7a8;
          svg {
            fill: #59d7a8;
          }
        }
      }
      .btn1 {
        color:#59d7a8;
      }
      .btn2 {
        color:#f4657a;
      }
      .err {
        position: absolute;width: 389px;left:0px;top:50px;font-size: 12px;color:#f44e55;
      }
    }
  }
}
</style>
